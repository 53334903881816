var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.studyUnit)?_c('div',{staticClass:"page-break"},[_c('b-table-simple',[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"pl-2 py-1 unit-section-header",staticStyle:{"text-align":"justify"},attrs:{"colspan":"5"}},[_c('div',{staticClass:"container-header-ra"},[_c('div',{staticClass:"mr-2"},[(_vm.allows_crud && !_vm.studyUnit.study_unit_locked_view)?_c('div',{staticClass:"unit-section-move handle",class:{ 'grabbable-cursor': _vm.allows_crud }},[_c('b-icon',{staticClass:"move-icon",attrs:{"icon":"arrows-expand","scale":"1"}})],1):_vm._e(),_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.ramicro", false, "RA Micro" ).toUpperCase())+" N°"+_vm._s(_vm.getCompetenceUnitOrder(_vm.temp_competence_unit))+_vm._s(_vm.studyUnit.order)+" "+_vm._s(_vm.studyUnit.name.toUpperCase())+" ")]),_c('div',{staticClass:"study-unit-button-div"},[(_vm.studyUnit.study_environments.length > 0)?_c('span',{staticClass:"tag-study-unit secondary-color p-1 rounded mr-2"},[_vm._v(" ("),_vm._l((_vm.studyEnvironments.filter((x) =>
                    _vm.studyUnit.study_environments.includes(x.id)
                  )),function(element,index){return _c('span',{key:element.id},[_vm._v(_vm._s(_vm.studyUnit.study_environments.length != index + 1 ? element.name + ", " : element.name))])}),_vm._v(") "+_vm._s(_vm.$getVisibleNames( "mesh.studyenvironment", true, "Ambientes de Aprendizaje" ))+" ")],2):_vm._e(),(_vm.studyUnit && _vm.studyUnit.hours > 0)?_c('span',{staticClass:"tag-study-unit secondary-color p-1 mr-2 rounded"},[_vm._v(" "+_vm._s(_vm.studyUnit.hours == null ? 0 : _vm.studyUnit.hours)+" Horas")]):_vm._e(),(_vm.matter && !_vm.matter.automatic_achievement_weighing)?_c('span',{staticClass:"tag-study-unit secondary-color p-1 rounded"},[_vm._v(" Ponderación: "+_vm._s(_vm.studyUnit.weighing)+"% ")]):_vm._e(),(
                  _vm.allows_crud &&
                  _vm.studyUnit &&
                  !_vm.studyUnit.study_unit_locked_view
                )?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.top",value:(
                  `Editar ${_vm.$getVisibleNames(
                    'teaching.ramicro',
                    false,
                    'RA Micro'
                  )}`
                ),expression:"\n                  `Editar ${$getVisibleNames(\n                    'teaching.ramicro',\n                    false,\n                    'RA Micro'\n                  )}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true,"top":true}}],staticClass:"ml-2",attrs:{"fill":"white"},on:{"click":function($event){return _vm.$bvModal.show(`edit-study-unit-modal-${_vm.study_unit_id}`)}}}):_vm._e()],1)])])],1),_c('b-tr',[_c('b-td',{staticClass:"text-center",class:{
            'w-35':
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc,
            'w-20': !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            ),
          }},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.ramicro", false, "RA Micro" ).toUpperCase())+" ")])]),_c('b-td',{staticClass:"text-center",class:{
            'w-35':
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc,
            'w-30': !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            ),
          }},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.criterio_de_evaluacion", true, "Criterios de Evaluación" ).toUpperCase())+" ")])]),_c('b-td',{staticClass:"w-30"},[_c('div',{staticClass:"content-header"},[_c('div',{staticClass:"mx-auto font-weight-bold",class:{ 'content-header-text': _vm.allows_crud }},[_c('span',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.studyunitcontent", true, "Contenidos" ).toUpperCase())+" ")])]),(_vm.allows_crud)?_c('div',{staticClass:"btn-add-content"},[(!_vm.studyUnit.study_unit_content_locked_view)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `Agregue los ${_vm.$getVisibleNames(
                    'teaching.studyunitcontent',
                    true,
                    'Contenidos'
                  )} por separado`
                ),expression:"\n                  `Agregue los ${$getVisibleNames(\n                    'teaching.studyunitcontent',\n                    true,\n                    'Contenidos'\n                  )} por separado`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"my-1 mr-1",attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`create-content-modal-${_vm.studyUnit.id}`)}}},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames( "teaching.studyunitcontent", false, "Contenido" ))+" ")]):_vm._e(),_c('b-modal',{attrs:{"id":`create-content-modal-${_vm.studyUnit.id}`,"title":`Crear ${_vm.$getVisibleNames(
                  'teaching.studyunitcontent',
                  false,
                  'Contenido'
                )}`,"size":"lg","hide-footer":""}},[_c('NewRichTextEditor',{on:{"save":_vm.createContent,"close":function($event){return _vm.$bvModal.hide(`create-content-modal-${_vm.studyUnit.id}`)}}})],1)],1):_vm._e()])]),(
            !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )
          )?_c('b-td',{staticClass:"text-center w-20",attrs:{"colspan":"1"}},[_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.methodologicalstrategy", true, "Estrategias Metodológicas" ).toUpperCase()))])]):_vm._e()],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',[(_vm.studyUnit)?_c('p',{staticClass:"d-flex m-0"},[_c('span',[_vm._v(" "+_vm._s(_vm.getCompetenceUnitOrder(_vm.temp_competence_unit))+_vm._s(_vm.studyUnit.order))]),_c('span',{class:{
                'pl-2': _vm.getCompetenceUnitOrder(_vm.temp_competence_unit) != null,
              }},[_c('SentenceContainer',{staticClass:"w-100 sentence-criteria",attrs:{"Sentence":_vm.studyUnit}})],1)]):_vm._e()]),_c('b-td',[_vm._l((_vm.evaluationCriteriaList),function(criteria){return [_c('div',{key:`criteria-${criteria.id}`,staticClass:"d-flex"},[_c('div',{staticClass:"d-flex w-100"},[(_vm.studyUnit)?_c('span',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(_vm.getCompetenceUnitOrder(_vm.temp_competence_unit))+_vm._s(_vm.studyUnit.order)+"."+_vm._s(criteria.order)+" ")]):_vm._e(),_c('SentenceContainer',{staticClass:"w-100 sentence-criteria",attrs:{"Sentence":criteria}})],1),(_vm.studyUnit && !_vm.studyUnit.automatic_achievement_weighing)?_c('div',{staticClass:"mx-1 text-center"},[_vm._v(" "+_vm._s(criteria.weighing)+"% ")]):_vm._e()])]}),(_vm.allows_crud)?_c('div',{staticClass:"text-center"},[(_vm.studyUnit)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `Editar ${_vm.$getVisibleNames(
                  'manual.criterio_de_evaluacion',
                  true,
                  'Criterios de Evaluación'
                )}`
              ),expression:"\n                `Editar ${$getVisibleNames(\n                  'manual.criterio_de_evaluacion',\n                  true,\n                  'Criterios de Evaluación'\n                )}`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`modal-evaluation-criteria-${_vm.study_unit_id}`)}}}):_vm._e()],1):_vm._e()],2),_c('b-td',[_c('draggable',{attrs:{"disabled":!_vm.allows_crud,"tag":"div","handle":".handle"},model:{value:(_vm.draggableList),callback:function ($$v) {_vm.draggableList=$$v},expression:"draggableList"}},[(_vm.draggableList.length > 0)?[_vm._l((_vm.draggableList),function(content){return [_c('div',{key:content.id,staticClass:"d-flex align-items-center border-draggable"},[(
                      _vm.allows_crud && !_vm.studyUnit.study_unit_content_locked_view
                    )?_c('div',{staticClass:"text-center handle",class:{ grabbable: _vm.allows_crud }},[(_vm.allows_crud)?_c('b-icon',{staticClass:"ml-1",attrs:{"icon":"arrows-expand","scale":"1.3"}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"content-div",attrs:{"id":`content-${content.id}`}},[_c('div',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(content.content)}})]),(_vm.allows_crud && content.id)?_c('div',{staticClass:"text-center"},[(!_vm.studyUnit.study_unit_content_locked_view)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                        `Editar ${_vm.$getVisibleNames(
                          'teaching.studyunitcontent',
                          false,
                          'Contenido'
                        )}`
                      ),expression:"\n                        `Editar ${$getVisibleNames(\n                          'teaching.studyunitcontent',\n                          false,\n                          'Contenido'\n                        )}`\n                      ",modifiers:{"v-secondary":true,"noninteractive":true}}],attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.$bvModal.show(`edit-content-modal-${content.id}`)}}}):_vm._e(),(!_vm.studyUnit.study_unit_content_locked_view)?_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                        `Eliminar ${_vm.$getVisibleNames(
                          'teaching.studyunitcontent',
                          false,
                          'Contenido'
                        )}`
                      ),expression:"\n                        `Eliminar ${$getVisibleNames(\n                          'teaching.studyunitcontent',\n                          false,\n                          'Contenido'\n                        )}`\n                      ",modifiers:{"v-secondary":true,"noninteractive":true}}],attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.deleteContent(content.id)}}}):_vm._e(),_c('b-modal',{attrs:{"id":`edit-content-modal-${content.id}`,"title":`Editar ${_vm.$getVisibleNames(
                        'teaching.studyunitcontent',
                        false,
                        'Contenido'
                      )}`,"size":"lg","hide-footer":""}},[_vm._v(" Copie y edite los "+_vm._s(_vm.$getVisibleNames( "teaching.studyunitcontent", true, "Contenidos" ))+" por separado "),_c('NewRichTextEditor',{attrs:{"Object":content,"Text":content.content,"show_last_modification":true,"object_last_modification":content},on:{"save":_vm.updateContent,"close":function($event){return _vm.$bvModal.hide(`edit-content-modal-${content.id}`)}}})],1)],1):_vm._e()])]})]:_vm._e()],2)],1),(
            !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )
          )?_c('b-td',[_c('div',{staticClass:"wrapper"},[_c('ul',{attrs:{"title":_vm.$getVisibleNames(
                  'mesh.methodologicalstrategy',
                  true,
                  'Estrategias Metodológicas'
                )}},_vm._l((_vm.selectedMethodologicalStrategies),function(strategy){return _c('li',{key:strategy.id},[_vm._v(" "+_vm._s(strategy.name)+" ")])}),0)]),(_vm.allows_crud && !_vm.studyUnit.study_unit_locked_view)?_c('div',{staticClass:"text-center"},[(_vm.studyUnit)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `Editar ${_vm.$getVisibleNames(
                  'mesh.methodologicalstrategy',
                  true,
                  'Estrategias Metodológicas'
                )}`
              ),expression:"\n                `Editar ${$getVisibleNames(\n                  'mesh.methodologicalstrategy',\n                  true,\n                  'Estrategias Metodológicas'\n                )}`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(
                  `modal-methodological-strategies-${_vm.study_unit_id}`
                )}}}):_vm._e()],1):_vm._e()]):_vm._e(),_c('div',[(_vm.studyUnit)?_c('b-modal',{attrs:{"title":`Modificar ${_vm.$getVisibleNames(
              'teaching.ramicro',
              false,
              'RA Micro'
            )}`,"size":"xl","hide-footer":true,"id":`edit-study-unit-modal-${_vm.study_unit_id}`}},[_c('StudyUnitForm',{attrs:{"matter_id":_vm.matter.id,"egress_profile_matter_id":_vm.egress_profile_matter_id,"StudyUnit":_vm.studyUnit,"temp_competence_unit":_vm.temp_competence_unit,"allows_crud":_vm.allows_crud,"show_temp_competence_unit":true,"show_delete_button":_vm.allows_crud},on:{"updated":_vm.slotUpdatedStudyUnit}})],1):_vm._e(),(_vm.studyUnit)?_c('b-modal',{attrs:{"title":`Seleccionar ${_vm.$getVisibleNames(
              'mesh.methodologicalstrategy',
              true,
              'Estrategias Metodológicas'
            )}`,"id":`modal-methodological-strategies-${_vm.study_unit_id}`,"size":"lg"},on:{"hidden":function($event){return _vm.closeMethodologicalStrategies(_vm.study_unit_id)}},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"custom-modal-title"},[_vm._v(" Seleccionar "+_vm._s(_vm.$getVisibleNames( "mesh.methodologicalstrategy", false, "Estrategia Metodológica" ))+" ")]),_c('InfoTooltip',{staticClass:"selection-info ml-0",attrs:{"tooltip_text":"Para seleccionar múltiples elementos, mantenga presionada la tecla Ctrl y a continuación, seleccione los elementos que desee."}})]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"btn-cancel",attrs:{"size":"sm"},on:{"click":function($event){return _vm.closeMethodologicalStrategies(_vm.study_unit_id)}}},[_vm._v("Cancelar")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.updateMethodologicalStrategies(_vm.study_unit_id)}}},[_vm._v("Guardar")])]},proxy:true}],null,false,749056177)},[_c('b-input-group',[_c('b-form-input',{attrs:{"type":"search","size":"sm","placeholder":`Buscar ${_vm.$getVisibleNames(
                  'mesh.methodologicalstrategy',
                  true,
                  'Estrategias Metodológicas'
                )}...`},model:{value:(_vm.input_search),callback:function ($$v) {_vm.input_search=$$v},expression:"input_search"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.input_search,"size":"sm"},on:{"click":function($event){_vm.input_search = ''}}},[_vm._v("Limpiar")])],1)],1),_c('small',{staticClass:"ml-1"},[_vm._v(" Se muestran "),_c('span',{staticStyle:{"color":"var(--kl-thirth-hover-color)"}},[_vm._v("coloreadas")]),_vm._v(" las "+_vm._s(_vm.$getVisibleNames( "mesh.methodologicalstrategy", true, "Estrategias Metodológicas" ))+" definidas como mínimas para la "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", false, "Asignatura" ))+". ")]),_c('b-form-select',{attrs:{"text-field":"name","value-field":"id","multiple":"","select-size":_vm.leftMethodologicalStrategies.length},model:{value:(_vm.methodological_strategies),callback:function ($$v) {_vm.methodological_strategies=$$v},expression:"methodological_strategies"}},[_vm._l((_vm.matterMethodologicalStrategies),function(meth){return _c('b-form-select-option',{key:meth.id,staticStyle:{"background-color":"var(--kl-thirth-hover-color)"},attrs:{"value":meth.id,"title":`Estratégias Mínimas de la ${_vm.$getVisibleNames(
                  'mesh.egressprofilematter',
                  false,
                  'Asignatura'
                )}`}},[_vm._v(_vm._s(meth.name))])}),_vm._l((_vm.searchMethodologicalStrategies),function(meth){return _c('b-form-select-option',{key:meth.id,attrs:{"value":meth.id}},[_vm._v(_vm._s(meth.name))])})],2)],1):_vm._e()],1)],1)],1)],1),_c('b-modal',{attrs:{"title":_vm.$getVisibleNames(
        'manual.criterio_de_evaluacion',
        true,
        'Criterios de Evaluación'
      ),"id":`modal-evaluation-criteria-${_vm.study_unit_id}`,"hide-footer":"","size":"lg"}},[_c('EvaluationCriteria',{attrs:{"allows_crud":_vm.allows_crud,"study_unit_id":_vm.study_unit_id}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }